import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import classNames from "classnames";
import { ReactComponent as OneBox } from "../../icons/one-box.svg";
import { ReactComponent as OneHelper } from "../../icons/one-helper.svg";
import { ReactComponent as TwoHelper } from "../../icons/two-helper.svg";

import "./MoveTypePicker.scss";

const helperCell = (title, description, iconNode) => {
  return (
    <div className="row helper-cell">
      <div className="col-2 img-wrapper">{iconNode}</div>
      <div className="col-10">
        <span className="title">{title}</span>
        <br />
        <span className="description">{description}</span>
      </div>
    </div>
  );
};

export default class AddressSuggestionList extends Component {
  render() {
    return (
      <div
        className="row justify-content-center help-selection"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="col-12 col-md-10 headline">
          Wie viele Helfer benötigst du an dieser Adresse?
        </div>
        <div className="col-12">
          <ListGroup>
            <ListGroupItem
              className={classNames({
                active: this.props.selectedMoveType === 0,
              })}
              tag="button"
              key="0"
              onClick={(e) => this.props.onMoveTypeClick(0)}
              action={true}
            >
              {helperCell(
                "Keine Hilfe",
                this.props.noHelperText,
                <OneBox width="60%" height="auto" />
              )}
            </ListGroupItem>
            <ListGroupItem
              className={classNames({
                active: this.props.selectedMoveType === 1,
              })}
              tag="button"
              key="1"
              onClick={(e) => this.props.onMoveTypeClick(1)}
              action={true}
            >
              {helperCell(
                "Fahrer hilft",
                this.props.oneHelperText,
                <OneHelper width="100%" height="auto" />
              )}
            </ListGroupItem>
            <ListGroupItem
              className={classNames({
                active: this.props.selectedMoveType === 2,
              })}
              tag="button"
              key="2"
              onClick={(e) => this.props.onMoveTypeClick(2)}
              action={true}
            >
              {helperCell(
                "Zwei Helfer",
                this.props.twoHelperText,
                <TwoHelper width="100%" height="auto" />
              )}
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
    );
  }
}
