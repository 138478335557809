import React, { useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as _ from "lodash";
import "./CityList.scss";
import Tracker from "../../../lib/Tracker";
import { PillButton } from "../PillButton";
import { MovematesModal } from "../MovematesModal";

type Props = {
  items: { name: string }[];
  onItemClick: (item: { name: string }) => void;
};

export const CityList: React.FC<Props> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className="city-list-wrapper">
        {props.items.map((item: { name: string }) => {
          return (
            <Button key={item.name} onClick={() => props.onItemClick(item)}>
              Region {_.capitalize(item.name)}
            </Button>
          );
        })}
        <Button
          className="other-city"
          key="other-city"
          onClick={() => {
            Tracker.track("city-selection-other-city");
            setIsModalOpen((prev) => !prev);
          }}
        >
          Deine Region ist nicht dabei?
        </Button>
      </div>
      <MovematesModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen((prev) => !prev)}
        centered
      >
        <ModalHeader toggle={() => setIsModalOpen((prev) => !prev)}>
          Das tut uns leid!
        </ModalHeader>
        <ModalBody>
          Im Moment ist Movemates in folgenden Regionen verfügbar:
          <br />
          <br />
          <ul>
            {props.items.map((i) => (
              <li key={i.name}>{_.capitalize(i.name)}</li>
            ))}
          </ul>
          Du möchtest, dass Movemates in Zukunft auch in deiner Region verfügbar
          ist? Schreib uns einfach{" "}
          <a href="mailto:service@movemates.de?subject=Movemates%20in%20meiner%20Region&body=Hallo%20Movemates-Team%20%E2%9D%A4%EF%B8%8F%2C%0D%0A%0D%0Aich%20w%C3%BCnsche%20mir%2C%20dass%20Movemates%20in%20Zukunft%20auch%20in%20________%20verf%C3%BCgbar%20ist.%20%F0%9F%9A%9A%F0%9F%9A%80%F0%9F%92%AA%0D%0A%0D%0AViele%20Gr%C3%BC%C3%9Fe">
            eine E-Mail
          </a>
          . Wir geben unser Bestes. 🚀
        </ModalBody>
        <ModalFooter>
          <PillButton
            color="primary"
            onClick={() => setIsModalOpen((prev) => !prev)}
          >
            OK
          </PillButton>
        </ModalFooter>
      </MovematesModal>
    </>
  );
};
