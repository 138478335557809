import { floorDescription, moveTypeDescription } from "../../lib/formatters";
import { Button } from "reactstrap";
import { ReactComponent as Edit } from "../../icons/icon_edit.svg";
import "./OrderSummary.scss";
import { useHistory } from "react-router";
import moment from "moment";

interface Props {
  pickup: any;
  delivery: any;
  items: any[];
  date: moment.Moment;
}

export const OrderSummary = (props: Props) => {
  const history = useHistory();

  console.log(props.pickup);

  return (
    <div className="address-summary-wrapper">
      {/* PICKUP */}
      <div className="row justify-content-between">
        <div className="col">
          <p className="address-title">Abholadresse</p>
          <p className="address-summary">
            <span>
              {props.pickup.location.title}
              <br />
            </span>
            {props.pickup.move_type !== 0 && (
              <span>
                {floorDescription(props.pickup.story)}{" "}
                {props.pickup.elevator ? "mit Fahrstuhl" : "ohne Fahrstuhl"}
                <br />
              </span>
            )}
            <p className="red-text">
              {moveTypeDescription("Abholadresse", props.pickup.move_type)}
            </p>
          </p>
        </div>
        <div className="col-3 edit-wrapper">
          <Button onClick={() => history.push("/address")}>
            <Edit />
          </Button>
        </div>
      </div>
      {/* DELIVERY */}
      <div className="row justify-content-between">
        <div className="col-12">
          <div className="spacer"></div>
        </div>
        <div className="col">
          <p className="address-title">Lieferadresse</p>
          <p className="address-summary">
            <span>
              {props.delivery.location.title}
              <br />
            </span>
            {props.delivery.move_type !== 0 && (
              <span>
                {floorDescription(props.delivery.story)}{" "}
                {props.delivery.elevator ? "mit Fahrstuhl" : "ohne Fahrstuhl"}
                <br />
              </span>
            )}
            <p className="red-text">
              {moveTypeDescription("Lieferadresse", props.delivery.move_type)}
            </p>
          </p>
        </div>
        <div className="col-3 edit-wrapper">
          <Button onClick={() => history.push("/address")}>
            <Edit />
          </Button>
        </div>
      </div>

      {/* ITEMS */}
      <div className="row justify-content-between">
        <div className="col-12">
          <div className="spacer"></div>
        </div>
        <div className="col">
          <p className="address-title">Gegenstände</p>
          {props.items.map((item, index) => {
            return (
              <>
                <span>
                  {item.quantity}x {item.name}
                </span>
                <br />
              </>
            );
          })}
        </div>
        <div className="col-3 edit-wrapper">
          <Button onClick={() => history.push("/items")}>
            <Edit />
          </Button>
        </div>
      </div>

      {/* DATE */}
      <div className="row justify-content-between">
        <div className="col-12">
          <div className="spacer"></div>
        </div>
        <div className="col address-summary">
          <p className="address-title">Datum & Uhrzeit</p>
          <p>{props.date.format("dddd, DD.MM.YYYY HH:mm")}Uhr</p>
        </div>
        <div className="col-3 edit-wrapper">
          <Button onClick={() => history.push("/datetime")}>
            <Edit />
          </Button>
        </div>
      </div>
    </div>
  );
};
