import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../shared/Card/Card";
import { OrderSummary } from "../OrderSummary/OrderSummary";
import FillingWrapper from "../shared/FillingWrapper/FillingWrapper";
import { BottomNavigationBar } from "../shared/BottomNavigationBar/BottomNavigationBar";
import { fetchEstimate } from "../../lib/api";
import { setEstimation } from "../../actions/estimation_actions";
import {
  fromToBaseParamsFromUserInput,
  itemParamsFromItems,
} from "../../lib/formatters";
import moment from "moment";
import Tracker from "../../lib/Tracker";
import { PriceTag } from "../PriceTag";
import styled from "styled-components";
import { RatingTag } from "../RatingTag";
import { Check } from "react-feather";

const Headline = styled.span`
  color: #323643;
  font-size: 1.3em;
  font-weight: bold;
`;

const Regular = styled.span`
  color: #323643;
  font-size: 0.9em;
`;

const CheckPoint = (props: { text: string }) => {
  return (
    <div className="row no-gutters">
      <div className="col-auto">
        <Check color="#417505" />
      </div>
      <div
        className="col"
        style={{ color: "#417505", fontSize: "1.1em", marginLeft: "12px" }}
      >
        {props.text}
      </div>
    </div>
  );
};

export const SummaryContainer: React.FC<{}> = () => {
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  const { userInput, items, city, estimation } = useSelector((state: any) => {
    return {
      userInput: state.user_input,
      items: state.items,
      city: state.city,
      estimation: state.estimation,
    };
  });

  useEffect(() => {
    Tracker.track("summary");
  }, []);

  const date = moment(userInput.date);
  const time = moment(userInput.time, "HH:mm");
  date.set("hour", time.get("hour"));
  date.set("minute", time.get("minute"));

  const dateParam = date.toJSON();
  const cityName = city.name;

  useEffect(() => {
    const refreshEstimate = async () => {
      setIsFetching(true);
      try {
        const fromToParams = fromToBaseParamsFromUserInput(userInput);
        const itemParams = itemParamsFromItems(items);
        const estimationParams = Object.assign({}, fromToParams, itemParams, {
          city: cityName,
          date: dateParam,
        });
        const response = await fetchEstimate(estimationParams);
        dispatch(setEstimation(response.data));
        Tracker.track("price-displayed", { amount: response.data.customer });
      } catch (error: any) {
        console.error(error);
        if (error.response) {
          alert(JSON.stringify(error.response.data));
        } else {
          alert(error.message);
        }
      } finally {
        setIsFetching(false);
      }
    };
    refreshEstimate();
  }, [userInput, items, dateParam, dispatch, cityName]);

  return (
    <FillingWrapper>
      <Card style={{ flexDirection: "column" }}>
        <div
          style={{
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "hidden",
            paddingBottom: "80px",
          }}
        >
          <div className="row no-gutters justify-content-center">
            <div
              className="col-12"
              style={{
                textAlign: "center",
                marginBottom: "8px",
                marginTop: "18px",
              }}
            >
              <Headline>Dein günstiger Festpreis</Headline>
            </div>
            <div
              className="col-11 col-lg-10"
              style={{ textAlign: "center", marginBottom: "18px" }}
            >
              <Regular>
                Für deinen Transport haben wir einen individuellen Festpreis
                berechnet, der dir unten angezeigt wird. Dieser Preis ist ein
                echter "All-inclusive" Preis. Es kommen keine weiteren Kosten
                auf dich zu!
              </Regular>
            </div>
            <div
              className="col-12"
              style={{ textAlign: "center", marginBottom: "24px" }}
            >
              <PriceTag
                price={estimation?.customer}
                isFetching={isFetching}
                size="lg"
              />
            </div>
            <div
              className="col-8 col-sm-9 col-md-11"
              style={{ marginBottom: "18px" }}
            >
              <div className="row no-gutters">
                <div className="col-12 col-md-6">
                  <CheckPoint text="Garantierter Festpreis" />
                  <CheckPoint text="Transportversicherung inkl." />
                </div>
                <div className="col-12 col-md-6">
                  <CheckPoint text="Mit 100% Zufriedenheitsgarantie" />
                  <CheckPoint text="Geschulte Transporteure und geprüfte Fahrzeuge" />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              style={{
                marginBottom: "42px",
              }}
            >
              <div className="row justify-content-center">
                <div className="col-auto">
                  <RatingTag type="google" value={5} />
                </div>
                <div className="col-auto">
                  <RatingTag type="facebook" value={5} />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              style={{
                textAlign: "center",
                marginBottom: "12px",
              }}
            >
              <Headline>Deine Transportdetails</Headline>
            </div>
            <div className="col-10">
              <OrderSummary
                pickup={userInput.pickup}
                delivery={userInput.delivery}
                items={items}
                date={date}
              />
            </div>
          </div>
        </div>
        <div style={{ flexShrink: 0 }}>
          <div className="row no-gutters justify-content-center">
            <div className="col-10">
              <BottomNavigationBar
                shouldShowNextButton={true}
                backTitle="Zurück zu Datum und Uhrzeit"
                isNextActive={true}
                nextTitle="Kontaktdaten eingeben"
                nextRoute="/contact"
              />
            </div>
          </div>
        </div>
      </Card>
    </FillingWrapper>
  );
};
