import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { rootReducer } from "./reducers";
import { Provider } from "react-redux";
import { Home } from "./components/Home/Home";
import "bootstrap/scss/bootstrap.scss";
import "./styles/global.scss";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";

const store = createStore(
  rootReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

TagManager.initialize({
  gtmId: "GTM-M674QRS",
  auth: "LWbIBYpk38nH02qxzZgKSw",
  preview: "env-21",
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Home />
      </Router>
    </Provider>
    ,
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
