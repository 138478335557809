import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../shared/Card/Card";
import FillingWrapper from "../shared/FillingWrapper/FillingWrapper";
import LoadingOverlay from "../shared/LoadingOverlay/LoadingOverlay";
import { BottomNavigationBar } from "../shared/BottomNavigationBar/BottomNavigationBar";
import { setOrder } from "../../actions/order_actions";
import {
  fromToParamsFromUserInput,
  itemParamsFromItems,
  contactParamsFromContactInput,
  generateThreeDSecureParams,
  invoiceRequestParamsFromContactInput,
} from "../../lib/formatters";
import Tracker from "../../lib/Tracker";
import moment from "moment";
import "./PaymentContainer.scss";
import { useHistory } from "react-router";
import { BraintreeWrapper, BraintreeWrapperRef } from "../BraintreeWrapper";
import { CreateOrderRequest, Order } from "../../model/Order";
import { createOrder } from "../../lib/api";
import { braintreeMerchantId } from "../../lib/constants";
import styled from "styled-components";
import { PriceTag } from "../PriceTag";
import {
  Alert,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { MovematesModal } from "../shared/MovematesModal";
import { OrderSummary } from "../OrderSummary/OrderSummary";
import { PillButton } from "../shared/PillButton";

const Regular = styled.span`
  color: #323643;
  margin: 20px 0px;
  @media (min-width: 576px) {
    font-size: 1.1em;
  }
`;

const InvisibleButton = styled(Button)`
  padding: 0;
`;

export const PaymentContainer: React.FC<{}> = () => {
  const { userInput, city, items, estimation, order } = useSelector(
    (state: any) => {
      return {
        userInput: state.user_input,
        city: state.city,
        items: state.items,
        estimation: state.estimation,
        order: state.order as Order,
      };
    }
  );

  const date = moment(userInput.date);
  const time = moment(userInput.time, "HH:mm");
  date.set("hour", time.get("hour"));
  date.set("minute", time.get("minute"));

  const dispatch = useDispatch();
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(true);
  const [isPaymentMethodValid, setIsPaymentMethodValid] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Zahlung wird vorbereitet..."
  );
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const braintreeRef = useRef<BraintreeWrapperRef>(null);

  useEffect(() => {
    Tracker.track("payment");
  }, []);

  const generateCreationRequest = (): CreateOrderRequest => {
    const fromToParams = fromToParamsFromUserInput(userInput);
    const itemParams = itemParamsFromItems(items);
    const contactParams = contactParamsFromContactInput(userInput.contact);
    const invoiceParams = invoiceRequestParamsFromContactInput(
      userInput.contact
    );

    const date = moment(userInput.date);
    const time = moment(userInput.time, "HH:mm");
    date.set("hour", time.get("hour"));
    date.set("minute", time.get("minute"));

    const request: CreateOrderRequest = Object.assign(
      { city: city.name, date: date },
      fromToParams,
      itemParams,
      contactParams
    );
    if (invoiceParams) {
      request.invoiceRequest = invoiceParams;
    }
    return request;
  };

  const handleNonceCreated = async (nonce: string, deviceData: string) => {
    const creationRequest = generateCreationRequest();
    // payment info is added
    creationRequest.payment = { braintreeNonce: nonce, deviceData };
    setIsFetching(true);
    try {
      const res = await createOrder(creationRequest);
      handlePaymentSuccess(res.data);
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        alert(JSON.stringify(error.response.data));
      } else {
        alert(error.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleSendToSupport = async () => {
    const creationRequest = generateCreationRequest();
    setIsFetching(true);
    try {
      const res = await createOrder(creationRequest);
      dispatch(setOrder(res.data));
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        alert(JSON.stringify(error.response.data));
      } else {
        alert(error.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handlePaymentSuccess = (order: Order) => {
    dispatch(setOrder(order));
    history.push("/finish");
  };

  return (
    <>
      <FillingWrapper>
        <Card style={{ flexDirection: "column" }}>
          {isFetching && <LoadingOverlay message={loadingMessage} />}
          <div style={{ flexGrow: 1, overflowY: "auto" }}>
            <div className="row justify-content-center">
              <div className="col col-10 payment-methods-wrapper">
                <div
                  className="row"
                  style={{ marginTop: "32px", marginBottom: "4px" }}
                >
                  <div className="col-auto">
                    <PriceTag
                      isFetching={false}
                      price={estimation?.customer}
                      size="sm"
                    />
                  </div>
                  <div className="col">
                    <Regular>
                      Bitte wähle eine Zahlungsmethode um deinen Auftrag
                      verbindlich zu buchen.
                    </Regular>
                    <br />
                    <InvisibleButton
                      color="link"
                      onClick={() => setIsOrderDetailsModalOpen(true)}
                    >
                      Deine Auftragsdetails
                    </InvisibleButton>
                  </div>
                </div>
                <BraintreeWrapper
                  ref={braintreeRef}
                  nonceCreated={handleNonceCreated}
                  isValidChanged={(v) => setIsPaymentMethodValid(v)}
                  isLoadingChanged={(v) => setIsFetching(v)}
                  threeDSParams={generateThreeDSecureParams(
                    userInput.contact,
                    estimation?.customer || 0
                  )}
                />
                <div className="security-explanation">
                  <p style={{ marginBottom: 0 }}>
                    Um deine Daten während des Bestellvorgangs optimal zu
                    schützen, haben wir diesen mit SSL verschlüsselt. Du
                    erkennst dies am Schloss-Symbol, das dein Webbrowser bei
                    einer SSL-Verbindung anzeigt. Secure Socket Layer (SSL)
                    ermöglicht eine sichere Datenübertragung über das Internet.
                  </p>
                  <InvisibleButton
                    color="link"
                    onClick={() => setIsHelpModalOpen(true)}
                    style={{ marginBottom: "10px", fontSize: "1em" }}
                  >
                    Ich brauche Hilfe bei der Bezahlung
                  </InvisibleButton>
                  <br />
                  <a
                    href={`https://www.braintreegateway.com/merchants/${braintreeMerchantId}/verified`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-dark.png"
                      style={{ border: 0 }}
                      width="160px"
                      height="auto"
                      alt="braintree secured"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div style={{ flexGrow: 0 }}>
            <div className="row no-gutters justify-content-center flex-stretching">
              <div className="col-10 align-self-end">
                <BottomNavigationBar
                  shouldShowNextButton={true}
                  backTitle="Zurück zu Kontaktdaten"
                  isNextActive={isPaymentMethodValid}
                  nextTitle="Jetzt bezahlen"
                  nextRoute=""
                  onNextClicked={() => {
                    setLoadingMessage("Zahlung wird verarbeitet...");
                    braintreeRef.current?.startPayment();
                  }}
                />
              </div>
            </div>
          </div>
        </Card>
      </FillingWrapper>
      <MovematesModal
        isOpen={isOrderDetailsModalOpen}
        toggle={() => setIsOrderDetailsModalOpen((prev) => !prev)}
        centered
      >
        <ModalHeader>Dein Movemates Auftrag</ModalHeader>
        <ModalBody>
          <OrderSummary
            pickup={userInput.pickup}
            delivery={userInput.delivery}
            items={items}
            date={date}
          />
        </ModalBody>
        <ModalFooter>
          <PillButton
            color="primary"
            onClick={() => setIsOrderDetailsModalOpen((prev) => !prev)}
          >
            OK
          </PillButton>
        </ModalFooter>
      </MovematesModal>
      <MovematesModal
        isOpen={isHelpModalOpen}
        toggle={() => setIsHelpModalOpen((prev) => !prev)}
        centered
      >
        <ModalHeader>Probleme bei der Bezahlung?</ModalHeader>
        <ModalBody>
          Wir helfen dir gerne. Du erreichst unser Support-Team ganztägig unter{" "}
          <a href="tel:+494022864899">040 22864899</a> oder per{" "}
          <a
            href={`mailto:service@movemates.de?subject=Ich%20brauche%20Hilfe%20bei%20der%20Bezahlung${
              order ? "%20von%20Auftrag%20" + order.order_number : ""
            }&body=Hallo%20Movemates%20Team%2C%0D%0A%0D%0Aich%20ben%C3%B6tige%20Hilfe%20bei%20der%20Bezahlung%20meines%20Auftrags${
              order
                ? "%20mit%20der%20Auftragsnummer%20" + order.order_number
                : ""
            }.%0D%0A%0D%0AIch%20habe%20folgendes%20Problem%3A%0D%0A%0D%0A_______________%0D%0A%0D%0AViele%20Gr%C3%BC%C3%9Fe`}
          >
            E-Mail
          </a>
          .
          <br />
          <br />
          Bitte sende uns deine Auftragsdaten unverbindlich zu, bevor du anrufst
          oder eine E-Mail schreibst.
          <br /> Mit Klick auf den Button erhälst du eine Auftragsnummer mit der
          wir deinen Auftrag in unserem System finden.
          <br />
          <br />
          {!order ? (
            <PillButton
              onClick={() => handleSendToSupport()}
              style={{ backgroundColor: "#60cda6" }}
            >
              {isFetching ? (
                <Spinner name="line-scale" color="#a2a8b5" fadeIn="quarter" />
              ) : (
                <span>Auftragsdaten an Support senden</span>
              )}
            </PillButton>
          ) : (
            <Alert color="success">
              Deine Auftragsnummer lautet: <b>{order.order_number}</b>
              <br />
              <br />
              Bitte gib diese Auftragsnummer ({order.order_number}) am Telefon
              oder in deiner E-Mail an.
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <PillButton
            color="primary"
            onClick={() => setIsHelpModalOpen((prev) => !prev)}
          >
            OK
          </PillButton>
        </ModalFooter>
      </MovematesModal>
    </>
  );
};
