import { Component } from "react";
import "./Banner.scss";

export default class banner extends Component {
  render() {
    return (
      <div
        className="row justify-content-between align-items-end"
        {...this.props}
      >
        <div className="col-6">
          <img
            src="/assets/images/banner-left.png"
            srcSet="/assets/images/banner-left.png 1x, /assets/images/banner-left@2x.png 2x, /assets/images/banner-left@3x.png 3x"
            className="banner"
          />
        </div>
        <div className="col-6">
          <img
            src="/assets/images/banner-right.png"
            srcSet="/assets/images/banner-right.png 1x, /assets/images/banner-right@2x.png 2x, /assets/images/banner-right@3x.png 3x"
            className="banner"
          />
        </div>
      </div>
    );
  }
}
