import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Card from "../shared/Card/Card";
import Banner from "../Banner/Banner";
import CenteredTitle from "../shared/CenteredTitle/CenteredTitle";
import { CityList } from "../shared/CityList/CityList";
import { fetchCities } from "../../lib/api";
import { setCity } from "../../actions/city_action";
import Spinner from "react-spinkit";
import Tracker from "../../lib/Tracker";
import { useHistory } from "react-router-dom";

export const CitySelectionContainer: React.FC<{}> = (props) => {
  const [cities, setCities] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    Tracker.track("city-selection");
    const loadCities = async () => {
      setIsFetching(true);
      try {
        const response = await fetchCities();
        setCities(response.data.cities);
      } catch (error: any) {
        console.error(error);
        alert(
          error.response ? JSON.stringify(error.response.data) : error.message
        );
      } finally {
        setIsFetching(false);
      }
    };
    loadCities();
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const citySelected = (city: any) => {
    dispatch(setCity(city));
    history.push("/address");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <div style={{ flexGrow: 1, flexShrink: 0, display: "flex" }}>
        <Banner />
      </div>
      <div style={{ flexGrow: 0, flexShrink: 1, display: "flex" }}>
        <Card style={{ justifyContent: "flex-end" }}>
          <div className="row no-gutters justify-content-center">
            <div className="col-10 col-md-8">
              <CenteredTitle title="In welcher Region möchtest du etwas transportieren?" />
            </div>
          </div>
          {isFetching ? (
            <div style={{ textAlign: "center", margin: "15px" }}>
              <Spinner name="line-scale" color="#a2a8b5" fadeIn="quarter" />
            </div>
          ) : (
            <div
              className="row no-gutters justify-content-center"
              style={{ margin: "15px 0px" }}
            >
              <div className="col-10">
                <CityList items={cities} onItemClick={citySelected} />
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
