export interface Route {
  pathname: string;
  title: string;
  shouldDisplayBackButton: boolean;
  shouldDisplayAttachmentImages: boolean;
  shouldDisplayProgressIndicator: boolean;
}

export const routes: Route[] = [
  {
    pathname: "/",
    title: "",
    shouldDisplayBackButton: false,
    shouldDisplayAttachmentImages: false,
    shouldDisplayProgressIndicator: true,
  },
  {
    pathname: "/address",
    title: "Abhol- und Lieferadresse",
    shouldDisplayBackButton: true,
    shouldDisplayAttachmentImages: true,
    shouldDisplayProgressIndicator: true,
  },
  {
    pathname: "/items",
    title: "Gegenstände",
    shouldDisplayBackButton: true,
    shouldDisplayAttachmentImages: true,
    shouldDisplayProgressIndicator: true,
  },
  {
    pathname: "/datetime",
    title: "Datum & Uhrzeit",
    shouldDisplayBackButton: true,
    shouldDisplayAttachmentImages: true,
    shouldDisplayProgressIndicator: true,
  },
  {
    pathname: "/summary",
    title: "Dein Movemates-Transport",
    shouldDisplayBackButton: true,
    shouldDisplayAttachmentImages: true,
    shouldDisplayProgressIndicator: true,
  },
  {
    pathname: "/contact",
    title: "Kontaktdaten",
    shouldDisplayBackButton: true,
    shouldDisplayAttachmentImages: true,
    shouldDisplayProgressIndicator: true,
  },
  {
    pathname: "/payment",
    title: "Zahlungsmethode",
    shouldDisplayBackButton: true,
    shouldDisplayAttachmentImages: true,
    shouldDisplayProgressIndicator: true,
  },
  {
    pathname: "/finish",
    title: "",
    shouldDisplayBackButton: false,
    shouldDisplayAttachmentImages: false,
    shouldDisplayProgressIndicator: false,
  },
];

export const undefinedRoute: Route = {
  pathname: "",
  title: "UNDEFINED ROUTE",
  shouldDisplayBackButton: true,
  shouldDisplayAttachmentImages: false,
  shouldDisplayProgressIndicator: false,
};

export const routeForPathname = (pathname: string): Route => {
  return routes.find((r) => r.pathname === pathname) || undefinedRoute;
};
