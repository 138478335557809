import { SET_CITY } from "../actions/city_action";

export default function (state = null, action) {
  switch (action.type) {
    case SET_CITY: {
      return action.city;
    }
    default:
      return state;
  }
}
