import {
  BaseAddress,
  Contact,
  CustomItem,
  InvoiceRequest,
  MoveType,
} from "../model/Order";
import { ThreeDSecureParams } from "../model/ThreeDSecure";
import { removeNonAscii } from "./ascii";

export const floorAbbreviation = (floor: number): string => {
  if (floor === 0) return "EG";
  if (floor < 0) return `${Math.abs(floor)}. UG`;
  return `${floor}. OG`;
};

export const floorDescription = (floor: number) => {
  if (floor === 0) return "Erdgeschoss";
  if (floor < 0) return `${Math.abs(floor)}. Untergeschoss`;
  return `${floor}. Obergeschoss`;
};

export const moveTypeString = (moveType: number): MoveType => {
  return moveType === 0
    ? MoveType.DriveOnly
    : moveType === 1
    ? MoveType.OneHelper
    : MoveType.TwoHelpers;
};

export const moveTypeDescription = (addressType: string, moveType: number) => {
  return moveType === 0
    ? `An der ${addressType} hast du keinen Helfer gebucht.`
    : moveType === 1
    ? `An der ${addressType} unterstützen wir dich mit einem Movemates-Helfer.`
    : `An der ${addressType} unterstützen wir dich mit zwei Movemates-Helfern.`;
};

export const fromToBaseParamsFromUserInput = (
  input: any
): { from: BaseAddress; to: BaseAddress } => {
  const from = {
    id: input.pickup.location.id,
    story: input.pickup.story,
    elevator: input.pickup.elevator,
    move_type: moveTypeString(input.pickup.move_type),
  };
  const to = {
    id: input.delivery.location.id,
    story: input.delivery.story,
    elevator: input.delivery.elevator,
    move_type: moveTypeString(input.delivery.move_type),
  };
  return {
    from: from,
    to: to,
  };
};

export const fromToParamsFromUserInput = (
  input: any
): { from: BaseAddress; to: BaseAddress } => {
  const baseParams = fromToBaseParamsFromUserInput(input);
  if (input.contact.has_alt_from) {
    baseParams.from.contact = {
      name: input.contact.alt_from_name,
      phone: input.contact.alt_from_phone,
    };
  }
  if (input.contact.has_alt_to) {
    baseParams.to.contact = {
      name: input.contact.alt_to_name,
      phone: input.contact.alt_to_phone,
    };
  }
  return baseParams;
};

export const itemParamsFromItems = (
  items: any[]
): {
  items: { id: string; quantity: number }[];
  custom_items: CustomItem[];
} => {
  const builtinItems = items
    .filter((i) => {
      return i.type === "builtin";
    })
    .map((i) => {
      return { id: i.id, quantity: i.quantity };
    });
  const customItems = items
    .filter((i) => {
      return i.type === "custom";
    })
    .map((i) => {
      return Object.assign({}, i.original, { quantity: i.quantity });
    });
  return { items: builtinItems, custom_items: customItems };
};

export const contactParamsFromContactInput = (input: any): Contact => {
  return {
    first_name: input.first_name,
    last_name: input.last_name,
    phone: input.phone,
    email: input.email,
    notes: input.notes || "",
  };
};

export const invoiceRequestParamsFromContactInput = (
  input: any
): InvoiceRequest | undefined => {
  if (input.wants_invoice) {
    const value = {
      name: input.invoice_name,
      street: input.invoice_street,
      houseNumber: input.invoice_house_number,
      city: input.invoice_city,
      zip: input.invoice_zip,
      shouldBeEmailedAutomatically: true,
    };
    if (input.invoice_company_name && input.invoice_company_name.length > 0) {
      (value as any).companyName = input.invoice_company_name;
    }
    return value;
  }
  return undefined;
};

export const generateThreeDSecureParams = (
  input: any,
  amount: number
): ThreeDSecureParams => {
  return {
    amount: centNumberToEurString(amount),
    email: input.email,
    billingAddress: {
      givenName: removeNonAscii(input.first_name),
      surname: removeNonAscii(input.last_name),
      phoneNumber: input.phone,
    },
  };
};

const centNumberToEurString = (input: number): string => {
  const rawAmountString = `${input}`;
  return `${
    rawAmountString.length < 3
      ? "0"
      : rawAmountString.slice(0, rawAmountString.length - 2)
  }.${rawAmountString.slice(rawAmountString.length - 2)}`;
};
