import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../shared/Card/Card";
import FillingWrapper from "../shared/FillingWrapper/FillingWrapper";
import OverlayCard from "../shared/OverlayCard/OverlayCard";
import CenteredTitle from "../shared/CenteredTitle/CenteredTitle";
import AddressButton from "../AddressButton/AddressButton";
import AddressSuggestion from "../AddressSuggestion/AddressSuggestion";
import AddressSelectionModal from "../AddressSelectionModal/AddressSelectionModal";
import { BottomNavigationBar } from "../shared/BottomNavigationBar/BottomNavigationBar";
import { setPickup, setDelivery } from "../../actions/userinput_action";
import { Transition } from "react-transition-group";
import Tracker from "../../lib/Tracker";
import { capitalize } from "lodash";
import { RouteMap } from "../Map/RouteMap";

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: "scale(1.2)",
  },
  entered: {
    opacity: 1,
    transform: "scale(1)",
  },
  exiting: {
    opacity: 0,
    transform: "scale(1.2)",
  },
  exited: {
    opacity: 0,
    transform: "scale(1.2)",
  },
  unmounted: {
    opacity: 0,
    transform: "scale(1.2)",
  },
};

const validateAddress = (address: any) => {
  return (
    address &&
    address.location !== null &&
    address.move_type !== null &&
    address.story !== null &&
    address.elevator !== null
  );
};

export const AddressContainer = () => {
  const [isPickupModalOpen, setIsPickupModalOpen] = useState(false);
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);

  const { city, pickup, delivery } = useSelector((state: any) => ({
    city: state.city,
    pickup: state.user_input.pickup,
    delivery: state.user_input.delivery,
  }));

  const [isUserClueless, setIsUserClueless] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    Tracker.track("address");
  }, []);

  useEffect(() => {
    const handle = setTimeout(() => {
      clearTimeout(handle);
      setIsUserClueless(true);
    }, 6000);
  }, [setIsUserClueless]);

  return (
    <FillingWrapper>
      <Card
        style={{
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
          }}
        >
          <RouteMap
            inititalCenter={city}
            startId={pickup.location ? pickup.location.id : undefined}
            endId={delivery.location ? delivery.location.id : undefined}
          />
        </div>

        <div
          style={{
            flexGrow: 0,
            flexShrink: 0,
            display: "flex",
          }}
        >
          <OverlayCard>
            <div className="row justify-content-center no-gutters">
              <div className="col-10">
                <CenteredTitle
                  title={`Wähle Abhol- und Lieferadresse für deinen Transport in ${capitalize(
                    city.name
                  )}`}
                />
              </div>
              <div className="col-10">
                <AddressButton
                  address={pickup}
                  onAddressClicked={() => setIsPickupModalOpen((v) => !v)}
                  placeholder="Abholadresse auswählen"
                  shouldDisplayClickIndicator={
                    !pickup.location && isUserClueless
                  }
                />
              </div>
              <div className="col-10">
                <AddressButton
                  address={delivery}
                  onAddressClicked={() => setIsDeliveryModalOpen((v) => !v)}
                  placeholder="Lieferadresse auswählen"
                  shouldDisplayClickIndicator={false}
                />
              </div>
              <div className="col-10">
                <BottomNavigationBar
                  shouldShowNextButton={true}
                  backTitle="Stadt wechseln"
                  isNextActive={
                    validateAddress(pickup) && validateAddress(delivery)
                  }
                  nextTitle="Gegenstände wählen"
                  nextRoute="/items"
                />
              </div>
            </div>
          </OverlayCard>
        </div>
        <Transition
          in={isPickupModalOpen}
          timeout={300}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(state) => (
            <AddressSelectionModal
              style={{
                transition: `all 300ms ease-out`,
                ...transitionStyles[state],
              }}
            >
              <AddressSuggestion
                toggle={() => setIsPickupModalOpen((v) => !v)}
                city={city}
                value={pickup}
                onValueChange={(v: any) => dispatch(setPickup(v))}
                noHelperText="Alles steht transportbereit am Bordstein. Der Fahrer hilft dir nur beim Beladen des Transporters."
                oneHelperText="Der Fahrer unterstützt dich beim Tragen. Zum Beispiel aus dem vierten Stock bis in den Transporter."
                twoHelperText="Du kannst dich zurücklehnen. Zwei Helfer nehmen dir die ganze Arbeit ab und tragen alles bis in den Transporter."
              />
            </AddressSelectionModal>
          )}
        </Transition>
        <Transition
          in={isDeliveryModalOpen}
          timeout={300}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(state) => (
            <AddressSelectionModal
              style={{
                transition: `all 300ms ease-out`,
                ...transitionStyles[state],
              }}
            >
              <AddressSuggestion
                toggle={() => setIsDeliveryModalOpen((v) => !v)}
                city={city}
                value={delivery}
                onValueChange={(v: any) => dispatch(setDelivery(v))}
                noHelperText="Der Fahrer hilft dir nur beim Entladen des Transporters. Vom Bordstein musst du die Sachen selbst an ihre endgültige Position bringen."
                oneHelperText="Der Fahrer unterstützt dich beim Tragen. Zum Beispiel aus dem Transporter bis in den vierten Stock."
                twoHelperText="Du kannst dich zurücklehnen. Zwei Helfer nehmen dir die ganze Arbeit ab und tragen alles aus dem Transporter bis an die endgültige Position."
              />
            </AddressSelectionModal>
          )}
        </Transition>
      </Card>
    </FillingWrapper>
  );
};
