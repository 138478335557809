import React from "react";
import { Button } from "reactstrap";
import classNames from "classnames";
import "./BottomNavigationBar.scss";
import { useHistory } from "react-router";
import { ArrowLeft, ArrowRight } from "react-feather";

interface Props {
  backTitle: string;
  shouldShowNextButton: boolean;
  isNextActive: boolean;
  nextRoute: string;
  onNextClicked?: () => void;
  nextTitle: string;
}

export const BottomNavigationBar: React.FC<Props> = (props) => {
  const history = useHistory();

  return (
    <div className="row justify-content-center no-gutters bottom-navigation-bar">
      <div className="d-none d-sm-block col-6">
        <Button className="back" onClick={history.goBack}>
          {props.backTitle}{" "}
          <ArrowLeft
            style={{ position: "absolute", top: "10px", left: "12px" }}
          />
        </Button>
      </div>
      <div
        className={classNames({
          "col-10 col-sm-6": true,
          "d-none": !props.shouldShowNextButton,
        })}
      >
        <Button
          className={classNames({
            next: true,
            active: props.isNextActive,
          })}
          onClick={() => {
            props.isNextActive && props.onNextClicked
              ? props.onNextClicked()
              : props.isNextActive && history.push(props.nextRoute);
          }}
        >
          {props.nextTitle}
          <ArrowRight
            style={{ position: "absolute", top: "10px", right: "12px" }}
          />
        </Button>
      </div>
    </div>
  );
};
